import { PT } from '../utils/available-languages'
import { BANGALO, CASA } from '../utils/available-accommodations'
import { BANGALO_PATH } from '../utils/routes'

import coverImage from '../assets/images/bangalo-do-soter/bangaloCover.webp'
import coverImageWide from '../assets/images/bangalo-do-soter/bangalo__CoverWide.webp'

export default function getFullData (language = PT) {
  return {
    id: BANGALO,
    coverImage,
    coverImageWide,
    facilities: bangaloMessages.facilities[language],
    minPeople: 2,
    maxPeople: 4,
    title: bangaloMessages.title[language],
    description: bangaloMessages.description[language],
    infos: bangaloMessages.infos[language],
    priceTableHeaders: bangaloMessages.priceTableHeaders[language],
    priceTableLines: bangaloMessages.priceTableLines[language],
    priceInfos: bangaloMessages.priceInfos[language],
    path: BANGALO_PATH,
    related: [CASA],
  }
}

export const getTitle = (language = PT) => bangaloMessages.title[language]

export const getCoverImage = () => coverImage

const bangaloMessages = {
  title: {
    [PT]: 'Bangalô do Sóter',
  },
  facilities: {
    [PT]: {
      pool: 'Piscina privativa',
      grill: 'Churrasqueira movel',
      sauna: 'Sauna privativa',
      wifi: 'Wifi',
      tv: 'TV com Sky e Netflix',
      kitchen: 'Cozinha completa',
    }
  },
  description: {
    [PT]: `
      O Bangalô é a opção ideal para casal que busca um ambiente romântico e aconchegante. Já para quem quer vir com os filhos, o Bangalô
      também é a escolha certa, oferecendo diversão e lazer. A acomodação possui um quarto, um mezanino com cama de casal, cozinha completa,
      deck com pisicina e churraqueira, além de sauna com vista para um lindo jardim com laguinho.`
  },
  infos: {
    [PT]: [
      'Disponibilizamos roupa de cama e banho.',
      'Não permitimos animais domésticos.'
    ]
  },
  priceTableHeaders: {
    [PT]: [
      '',
      'Até 2 pessoas',
      'Até 4 pessoas'
    ],
  },
  priceTableLines: {
    [PT]: [
      [
        {
          text: 'Pacote Final de Semana',
          description: '(sex à dom)'
        },
        {
          text: 'R$1.200,00',
        },
        {
          text: 'R$1.500,00',
        }
      ],
      [
        {
          text: 'Pacote Dias de Semana',
          description: '(seg à qui)'
        },
        {
          text: 'R$1.200,00',
        },
        {
          text: 'R$1.500,00',
        }
      ],
      [
        {
          text: 'Feriados',
        },
        {
          text: 'Consultar',
        },
        {
          text: 'Consultar',
        }
      ],
    ],
  },
  priceInfos: {
    [PT]: [
      'Para utilizar a sauna há uma cobrança adicional de R$80,00 (opcional).',
    ]
  },
}
